const serverUrl = "https://stageservice.complygate.co.uk"
export const environment = {
  serverUrl: `${serverUrl}`,
  apiUrl:`${serverUrl}/api`,
  production: true,
  APIEndpoint: "https://stageservice.complygate.co.uk",
  websiteurl: "https://stage.complygate.co.uk",
  stripkey: "pk_test_dIWR2H2xFoFj3I8vXg8eS4dw",
  amlkey: "e7926ed1-81dd-4c6e-9622-982525e7ff43",
  amlurl: "https://api1.uat.c6-intelligence.com/api/v2_1/api",
};

