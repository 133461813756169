import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor() { }
  static apiRoutes = {
    validayeemail: `${environment.apiUrl}/verifyemails/verifyemail/`,
    login: `${environment.serverUrl}/login`,
    ssologin: `${environment.serverUrl}/ssologin`,
    oktalogin: `${environment.serverUrl}/oktalogin`,
    loginbyaccesstoken: `${environment.serverUrl}/loginbyaccesstoken`,
    signup: `${environment.apiUrl}/Users`,
    createconsertfile:`${environment.serverUrl}/createconsertfile`,
    questiontemplates: `${environment.apiUrl}/modulequestiontemplates`,
    questiontemplatedetail: `${environment.apiUrl}/modulequestiontemplatedetails`,
    createferencerequests: `${environment.apiUrl}/referencerequests`,
    createtemplaterequests: `${environment.apiUrl}/modulequestiontemplates`,
    createquestionrequests: `${environment.apiUrl}/modulequestiontemplatedetails`,
    deletetemplaterequests: `${environment.apiUrl}/modulequestiontemplates`,
    deletequestionequests: `${environment.apiUrl}/modulequestiontemplatedetails`,
    updatequestionrequests: `${environment.apiUrl}/modulequestiontemplatedetails`,
    referencerequestsget: `${environment.apiUrl}/referencerequests`,
    companydetailget: `${environment.apiUrl}/companies`,
    userdetailget: `${environment.apiUrl}/users`,
    createreferee: `${environment.apiUrl}/referees`,
    updatereferee: `${environment.apiUrl}/referees`,
    refereeget: `${environment.apiUrl}/referees`,
    createrefereeresponse: `${environment.apiUrl}/refereeresponses/createrefereeresponse`,
    refereeresponseget: `${environment.apiUrl}/refereeresponses`,
    referencemodeluploadfile: `${environment.apiUrl}/containers/referencemodel/upload`,
    referencemodelgetuploadedfile: `${environment.apiUrl}/containers/referencemodel/download`,
    adminSetupCreateCompany: `${environment.apiUrl}/companies`,
    adminSetupGetDesignation: `${environment.apiUrl}/designations`,
    adminSetupCreateDesignation: `${environment.apiUrl}/designations`,
    adminSetupUpdateDesignation: `${environment.apiUrl}/designations/update`,
    adminSetupDefaultQuestion: `${environment.apiUrl}/defaultquestions`,
    adminSetupDefaultQuestionCreate: `${environment.apiUrl}/defaultquestions`,
    adminSetupDefaultDelete: `${environment.apiUrl}/defaultquestions`,
    getallcasestoassign: `${environment.serverUrl}/casestoassign`,
    getallCasedetailsbyadmin: `${environment.serverUrl}/getallCasedetailsbyadmin`,
    getallCasedetailsunassignbyadmin: `${environment.serverUrl}/getallCasedetailsunassignbyadmin`,
    getusercases: `${environment.serverUrl}/usersdetail/assignedcases`,
    getip: 'http://api.ipify.org/',
    getdesignations: `${environment.apiUrl}/designations`,
    deletereferencerequest: `${environment.apiUrl}/referencerequests`,
    updatereferencerequest: `${environment.apiUrl}/referencerequests/update`,
    listcaseiniatedcount: `${environment.apiUrl}/Casegenerations/listcaseiniatedcount`,    
    companyCreditSummary: `${environment.apiUrl}/modulecreditdebithistories/listcompanychecks`,
    addCompanyCredit: `${environment.apiUrl}/modulecreditdebithistories`,
    listCredits: `${environment.apiUrl}/modulecreditdebithistories`,
    bulkreferencerequests: `${environment.apiUrl}/bulkreferencerequests`,
    casegenerations: `${environment.apiUrl}/casegenerations`,
    listreferencedashboarddata: `${environment.apiUrl}/Casegenerations/listreferencedashboarddata`,
    downloadrefereewithresponse: `${environment.serverUrl}/referee/downloadrefereewithresponse`,
    /////////// New API constants
    modulequestiontemplate: `${environment.serverUrl}/reference/modulequestiontemplate`,
    adminSetupGetDesignations: `${environment.serverUrl}/designations`,
    adminSetupGetStatuses: `${environment.serverUrl}/statuses`,
    getallrefereedata: `${environment.serverUrl}/referee/getallrefereedata`,
    getrefereedataforresponse: `${environment.serverUrl}/referee/getrefereedataforresponse`,
    adminSetupCreateDesignations: `${environment.serverUrl}/designations`,
    adminSetupUpdateDesignations:`${environment.serverUrl}/designations`,
    adminSetupDefaultQuestions: `${environment.serverUrl}/defaultquestions`,
    adminSetupDefaultQuestionsCreate: `${environment.serverUrl}/defaultquestions`,
    adminSetupDefaultQuestionsDelete: `${environment.serverUrl}/defaultquestions`,
    adminsetupgetcompany: `${environment.serverUrl}/company`,
    screeningusercompanies: `${environment.serverUrl}/screeningusercompanies`, // get screening user companies
    screeningteamusercompanies: `${environment.serverUrl}/screeningteamusercompanies`, // get screening user companies


    admingetcompany: `${environment.serverUrl}/geteditcompany`,
    createcompany: `${environment.serverUrl}/company`,
    updatecompany: `${environment.serverUrl}/company/update`,
    hrinformation: `${environment.serverUrl}/company/hrinformation`,
    hrinformationbyid: `${environment.serverUrl}/company/hrinformationbyid`,   
    hrinformationbyname : `${environment.serverUrl}/company/hrinformationbyname`,   
    adminSetupCreateUserDetails: `${environment.serverUrl}/usersdetail`,  //get user detail  
    adminSetupUserDetailas: `${environment.serverUrl}/usersdetail`,  //get user detail, note: both functions were in used 
    screeningadminuserdetail: `${environment.serverUrl}/screeningadminuserdetail`,
    adminSetupCreateUser: `${environment.serverUrl}/user`,
    adminSetupUpdateUser: `${environment.serverUrl}/companyuser/update`,
    regeneratepassword: `${environment.serverUrl}/regeneratepassword`,
    regeneratepasswordbyadmin: `${environment.serverUrl}/regeneratepasswordbyadmin`, // Regenerate Password by Superadmin
    updateconsentbyadmin: `${environment.serverUrl}/updateconsentbyadmin`, // Upload Consent for candidate by Superadmin
    updatecasestatusbyadmin: `${environment.serverUrl}/updatecasestatusbyadmin`, // update case status for the candidate
    getadminusers: `${environment.serverUrl}/getadminusers`, // get case management users
    companyusers: `${environment.serverUrl}/companyusers`, // get all users for a company
    getscreeninguserlist: `${environment.serverUrl}/getscreeninguserlist`, // get screening user list
    getscreeninguserlistforcandidate: `${environment.serverUrl}/getscreeninguserlistforcandidate`, // get screening user list
    adminSetupCreateUserDetailsUpdates: `${environment.serverUrl}/usersdetail/update`,
    assigncases: `${environment.serverUrl}/usersdetail/assigncases`, // assign cases to the user
    transfercases: `${environment.serverUrl}/usersdetail/transfercase`, // transfer case from one user to another
    transfercasesapproved: `${environment.serverUrl}/usersdetail/transfercaseapproved`,
    transfercasesreject: `${environment.serverUrl}/usersdetail/transfercasereject`,
    getallprofiles: `${environment.serverUrl}/profiles`,
    companyprofiles: `${environment.serverUrl}/companyprofiles`,
    companyuserprofiles: `${environment.serverUrl}/companyuserprofiles`,
    usertokenbyadmin: `${environment.serverUrl}/usertokenbyadmin`,
    getuserprofiles: `${environment.serverUrl}/userprofiles`, 
    createprofile: `${environment.serverUrl}/profiles`,
    updateprofile: `${environment.serverUrl}/profiles`,
    adminSetupmoduleGetMaster: `${environment.serverUrl}/modules`,
    adminSetupmoduleGet: `${environment.serverUrl}/moduleaccess/listmoduleaccess`,
    adminSetupmodulePost: `${environment.serverUrl}/moduleaccess/createmoduleaccess`,
    listuserchecks: `${environment.serverUrl}/modules/getuserchecks`,
    modulequestiontemplatedetail: `${environment.serverUrl}/reference/modulequestiontemplatedetail`,
    referencerequest: `${environment.serverUrl}/reference/referencerequest`,
    screeningreferencerequest: `${environment.serverUrl}/reference/screening/referencerequest`,
    referee: `${environment.serverUrl}/reference/referee`,
    sendrefereelink: `${environment.serverUrl}/reference/sendrefereelink`,
    screeningreferee: `${environment.serverUrl}/reference/screening/referee`,
    addnotes: `${environment.serverUrl}/reference/screening/addnotes`,
    getcasenotes: `${environment.serverUrl}/screening/getcasenotes`,
    getcaseemails: `${environment.serverUrl}/screening/getcaseemails`,
    caseusernotes: `${environment.serverUrl}/screening/getcaseusernotes`,
    caseallusernotes: `${environment.serverUrl}/screening/getusernotes`,
    getcasenote: `${environment.serverUrl}/screening/getcasenote`,
    updatecasenote: `${environment.serverUrl}/screening/updatenotes`,
    refereeresponse: `${environment.serverUrl}/reference/refereeresponse`,   
    forgotPassword: `${environment.serverUrl}/forgot-password`,    
    updateemail: `${environment.serverUrl}/updateemail`,
    updatephone: `${environment.serverUrl}/updatephone`,    
    validatepasscode: `${environment.serverUrl}/usersdetail/validateemailphonepasscode`,
    resendSms: `${environment.serverUrl}/usersdetail/resendsms`,
    resendemailpasscode: `${environment.serverUrl}/usersdetail/resendemailpasscode`,
    casegeneration:`${environment.serverUrl}/casegeneration`,    
    sendtoreview:`${environment.serverUrl}/sendtoreview`,
    submitreview: `${environment.serverUrl}/submitreview`,
    resendemail: `${environment.serverUrl}/resendemail`,
    pausecase: `${environment.serverUrl}/pausecase`,
    deletecase: `${environment.serverUrl}/deletecase`, // To delete a case
    updateredflag: `${environment.serverUrl}/updateredflag`,
    updatecasedetails: `${environment.serverUrl}/updatecasedetails`, // To update the details of case
    updateconditionalscreening: `${environment.serverUrl}/updateconditionalscreening`,
    updatesubmitapplication:`${environment.serverUrl}/updatesubmitapplication`,
    casecandidateupdate: `${environment.serverUrl}/lastcandidateupdatecase`,
    requesttoedit: `${environment.serverUrl}/requesttoedit`,
    casestatus:`${environment.serverUrl}/casestatus`,
    casestatussummaryview:`${environment.serverUrl}/casestatussummaryview`,
    dashboarddata:`${environment.serverUrl}/dashboarddata`,
    casereportdata:`${environment.serverUrl}/casereportdata`,
    getscreeningusercases: `${environment.serverUrl}/getscreeningusercases`, // Screening user cadidate list
    getscreeningusercasesrequest: `${environment.serverUrl}/getscreeningusercasesrequest`, // Screening user Transfer Request cadidate list
    getscreeningteamcases: `${environment.serverUrl}/getscreeningteamcases`, // Team Leader Screening user cadidate list
    getcompanycandidates: `${environment.serverUrl}/getcompanycandidates`, // To get all candidates for the company
    uploadfile: `${environment.apiUrl}/fileuploads/complygate/upload`,
    profilepicupload: `${environment.serverUrl}/profile/uploadprofile`,
    updatecasetoken: `${environment.serverUrl}/updatecasetoken`,
    sendlinkviasms: `${environment.serverUrl}/sendlink`, // To send url for uploading visa, passport, dl by mobile
    submitdbs: `${environment.serverUrl}/submitdbs`, // To send the data to check Scotland dbs
    pdfuploadtomanualcheck: `${environment.serverUrl}/manualcheck/uploadpdf`,
    downloadfile: `${environment.apiUrl}/fileuploads/complygate/download`,
    casestatusdetailedview:`${environment.serverUrl}/casestatusdetailedview`,
    screeningusercaseview: `${environment.serverUrl}/screeningusercaseview`, // Screening user case details
    manualcheckreportupdate: `${environment.serverUrl}/manualcheckreportupdate`,
    screeningusercandidatedetails:`${environment.serverUrl}/screeningusercandidatedetails`, // Screening user candidate details
    downloadfullreport: `${environment.serverUrl}/downloadfullreport`,
    downloadfullreportnew: `${environment.serverUrl}/downloadfullreportnew`,
    getcaselog: `${environment.serverUrl}/getcaselog`, // To get log for particular case
    getmanualcheckrequests: `${environment.serverUrl}/getmanualcheckrequests`,
    getscreeningcheckrequests: `${environment.serverUrl}/getscreeninguserrequests`,
    casestatusdetailedviewid:`${environment.serverUrl}/casestatusdetailedviewid`,
    manualcheckview:`${environment.serverUrl}/getmanualcheckview`,
    screeningusermanualcheckview: `${environment.serverUrl}/screeningusermanualcheckview`,
    candidatedetails:`${environment.serverUrl}/candidatedetails`,
    updatecandidatedetailsbycandidateid: `${environment.serverUrl}/updatecandidatedetailsbycandidateid`,
    postcandidatedetails:`${environment.serverUrl}/candidatedetails`,
    generatepassportpdf:`${environment.serverUrl}/generatepassportpdf`,
    customfileupload:`${environment.serverUrl}/customfileupload`,
    customfiledownload:`${environment.serverUrl}/customfiledownload`,
    menupathfromto :`${environment.serverUrl}/moduleaccess/menupathfromto`,
    generatevisapdf:`${environment.serverUrl}/generatevisapdf`,
    generateidproofdocumentpdf: `${environment.serverUrl}/generateidproofdocumentpdf`,
    generatertwdocumentpdf: `${environment.serverUrl}/generatertwdocumentpdf`,
    customvalidayeemail: `${environment.serverUrl}/verifyemail/`,
    validateusername: `${environment.serverUrl}/verifyusername`,
    companycasebalancesummaryview: `${environment.serverUrl}/companycasebalancesummaryview`,
    companybyid: `${environment.serverUrl}/companybyid`,
    modulecreditdebithistory: `${environment.serverUrl}/modulecreditdebithistory`,
    modulecreditdebithistoryadmin : `${environment.serverUrl}/modulecreditdebithistoryadmin`,
    bulkcasegeneration:`${environment.serverUrl}/bulkcasegeneration`,
    generateadversecreditreport: `${environment.serverUrl}/generateadversecreditreport`,
    generatecompanyadvertdata:`${environment.serverUrl}/generatecompanyadvertdata`,
    updatemypassword:`${environment.serverUrl}/updatemypassword`,
    logindetails:`${environment.serverUrl}/logindetails`,
    casegenerationcountstatus:`${environment.serverUrl}/casegenerationcountstatus`,
    generatenorecordfoundpdf:`${environment.serverUrl}/generatenorecordfoundpdf`,
    generatetaxdefaulterpdf:`${environment.serverUrl}/generatetaxdefaulterpdf`,
    checkuseraccess: `${environment.serverUrl}/checkuseraccess`,
    candidatesocialmedia:`${environment.serverUrl}/candidatesocialmedia`,
    candidatedetailsnew:`${environment.serverUrl}/candidatedetailsnew`,
    sharecodesave:`${environment.serverUrl}/sharecodesave`,
    candidateaddress:`${environment.serverUrl}/candidateaddress`,
    candidatefinancial:`${environment.serverUrl}/candidatefinancial`,
    candidatesanctions:`${environment.serverUrl}/candidatesanctions`,
    candidatedeclarations:`${environment.serverUrl}/candidatedeclarations`,
    casegenerationreset:`${environment.serverUrl}/casegenerationreset`,
    casegenerationwithdrawal:`${environment.serverUrl}/casegenerationwithdrawal`,
    candidateemploymentinfo:`${environment.serverUrl}/candidateemploymentinfo`,
    candidateotherdocuments:`${environment.serverUrl}/candidateotherdocuments`,
    candidateeligibilitytowork:`${environment.serverUrl}/candidateeligibilitytowork`,
    candidatedeclarationsbycandidateid:`${environment.serverUrl}/candidatedeclarationsbycandidateid`,
    candidateemploymentgap:`${environment.serverUrl}/candidateemploymentgap`,
    completeemploymentgap:`${environment.serverUrl}/completeemploymentgap`,
    candidateemploymentgapwithoutsaving: `${environment.serverUrl}/candidateemploymentgapwithoutsaving`,
    addresshistory:`${environment.serverUrl}/addresshistory`,
    addresshistorywithoutsaving: `${environment.serverUrl}/addresshistorywithoutsaving`,
    candidatequalification:`${environment.serverUrl}/candidatequalification`,
    candidateprofessionalmembership:`${environment.serverUrl}/candidateprofessionalmembership`,
    candidatesialicence:`${environment.serverUrl}/candidatesialicence`,
    candidatesialicencewithoutsaving: `${environment.serverUrl}/candidatesialicencewithoutsaving`,
    candidatecscs:`${environment.serverUrl}/candidatecscs`, // CSCS
    candidateeusr:`${environment.serverUrl}/candidateeusr`, // EUSR
    candidateswqr:`${environment.serverUrl}/candidateswqr`, // SWQR
    emailtemplates:`${environment.serverUrl}/emailtemplates`,
    sendemail: `${environment.serverUrl}/sendemail`,
    usersbycompany: `${environment.serverUrl}/usersbycompany`,
    screeningcandidateview:`${environment.serverUrl}/screeningcandidateview` // For the screening user to view Candidate 
  }

  static reqHeader = new HttpHeaders(
    {
      'Content-Type': 'application/json'
    });

  static apiMethods = {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete',
    patch: 'patch'
  }

  static localStorageKeys = {
    token: 'User_Token',
    userData: 'User_Data'
  }

  static errorMessages = {

  }

  static successMessage = {

  }

}
