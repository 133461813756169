import { Injectable, Inject } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from 'src/app/services/core/auth/auth.service';
import {ConstantService} from 'src/app/shared/constant/constant.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  implements CanActivate{

  constructor(private auth: AuthService, private router: Router,@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
  canActivate() {
    const authToken = this._oktaAuth.getAccessToken();
    if(this.auth.getToken() || authToken){
      return true;
    }else{
      if(this.router.url === "/register"){
        return true;
      }else{
        this.router.navigate(['/login']);
        return false;
      }
    }
  }
}
