import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
declare var require: any
// import { Observable } from 'rxjs/Observable';
import { Observable } from 'rxjs';
//import { FileSelectDirective } from 'ng2-file-upload';
var Buffer = require('buffer/').Buffer
@Injectable()


export class UploadFileService {
  //response: any;
  filename= {};
  url={};
  FOLDER = 'screening/';
  isuploading=false;

  constructor() { }


  uploadfile(file): Promise<any> 
  {
    const contentType = file.type;
    var userid =JSON.parse(localStorage.getItem('User_Data')).userid;;
    var empname = localStorage.getItem('Name');
    // console.log(file);
    var fileext = file.name.split('.').pop();
    const bucket = new S3({
      accessKeyId: 'AKIARJCDXR3VSWIYBLON',
      secretAccessKey: 'T2qcxeMGQGN9ARD0vg0I+fYVjThK1ql4nJ+hX//4',
      region: 'us-east-1'
    });

    // var nm = this.getFileNameWithoutExtension(file.name.replace(' ','')).substring(0,20);
    // nm = nm.replace(/[^a-zA-Z ]/g, "");

    const params = {
      Bucket: 'complygate',
      Key: this.FOLDER + 'employee/' + userid + '/'+userid+ Date.now() + "." + fileext,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,            
      // ACL: 'public-read'
    };


    return new Promise((resolve, reject) => {
      bucket.upload(params, (err, data) => err ? resolve(err) : resolve(data.Location))    
    });


    // let fddf;

    // var response = await bucket.upload(params, function (err, data) {


    //    console.log('File upload data Error', err);


    //    console.log('File upload data', data.Location);

    //    fddf=data.Location;

    //    this.isuploading = true;

    //    return fddf;
    //   // if (err){
    //   //   return false;
    //   // } else{
    //   //   return "https://complygate.s3.amazonaws.com/" + params.Key;  
    //   //   // return true;
    //   // }
    // });

     
  }


  uploadfilebase64(file){
    
    var userid =JSON.parse(localStorage.getItem('User_Data')).userid;
    var empname = localStorage.getItem('Name');
   // var fileext = file.name.split('.').pop();
    const bucket = new S3({
      accessKeyId: 'AKIARJCDXR3VSWIYBLON',
      secretAccessKey: 'T2qcxeMGQGN9ARD0vg0I+fYVjThK1ql4nJ+hX//4',
      region: 'us-east-1'
    });

    // console.log(file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""));
    var nm ='asfdafdasfas';// this.getFileNameWithoutExtension(file.name).substring(0,20)
    // console.log()
    var  buf = Buffer.from(file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""),'base64');
   
    const params = {
      Bucket: 'complygate',
      Key: this.FOLDER + 'employee/' + userid + '/'+userid+Date.now()+".jpeg",
      Body: buf ,//file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""),
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg',
      ACL: 'public-read'
    };
    


    return new Promise((resolve, reject) => {
      bucket.upload(params, (err, data) => err ? resolve(err) : resolve(data.Location))    
    });
    // var response = bucket.upload(params, function (err, data) {
    //   if (err){
    //     return false;
    //   } else{
    //     return true;
    //   }
    // });
    // var imgurl = ("https://complygate.s3.amazonaws.com/" + params.Key);
    // return imgurl;  
  }



  
  uploadfilebase64_sign(file){
    
    var userid =JSON.parse(localStorage.getItem('User_Data')).userid;
    var empname = localStorage.getItem('Name');
   // var fileext = file.name.split('.').pop();
    const bucket = new S3({
      accessKeyId: 'AKIARJCDXR3VSWIYBLON',
      secretAccessKey: 'T2qcxeMGQGN9ARD0vg0I+fYVjThK1ql4nJ+hX//4',
      region: 'us-east-1'
    });

    // console.log(file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""));
    var nm ='asfdafdasfas';// this.getFileNameWithoutExtension(file.name).substring(0,20)
    // console.log()
    var  buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64');
  
    const params = {
      Bucket: 'complygate',
      Key: this.FOLDER + 'employee/' + userid + '/'+userid+Date.now()+".jpeg",
      Body: buf ,//file.imageAsDataUrl.replace(/^data:image\/\w+;base64,/, ""),
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg',
      ACL: 'public-read'
    };
    


    return new Promise((resolve, reject) => {
      bucket.upload(params, (err, data) => err ? resolve(err) : resolve(data.Location))    
    });
    // var response = bucket.upload(params, function (err, data) {
    //   if (err){
    //     return false;
    //   } else{
    //     return true;
    //   }
    // });
    // var imgurl = ("https://complygate.s3.amazonaws.com/" + params.Key);
    // return imgurl;  
  }

  getFileNameWithoutExtension(name) {
    //  var name = file.getName();

    //console.log(name.length);

      var pos = name.lastIndexOf('.');
      if (pos > 0 && pos < (name.length - 1)) {
          // there is a '.' and it's not the first, or last character.
          return name.substring(0,  pos);
      }
      return name;
  }
  
}
