import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AuthService} from 'src/app/services/core/auth/auth.service';
import { Observable, map, of, catchError } from 'rxjs';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/observable/empty';
// import 'rxjs/add/operator/retry'; // don't forget the imports
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private auth:AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler){
   if(this.auth.getToken()){
     req = req.clone({
      setHeaders:{
        'X-Access-Token': `${this.auth.getToken()}`,
        'access_token': `${this.auth.getToken()}`,
        'Authorization': `${this.auth.getToken()}` 
         // 'Content-Type': 'application/json'       
       }
     })
   }
   return next.handle(req)
   .pipe(map(event => {
    if (event instanceof HttpResponse ) {
        event = event.clone({ body: this.modifyBody(event) })
    }         
    return event;
    }))
   .pipe(catchError((err: HttpErrorResponse) => {

    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
    }

    // ...optionally return a default fallback value so app can continue (pick one)
    // which could be a default value (which has to be a HttpResponse here)
    // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
    // or simply an empty observable
    //return Observable.empty<HttpEvent<any>>();
    try{
      if(err.error.error.message.includes("Email already exists"))
      return of(new HttpResponse({body: [{error: "Email already exists"}]}));
    else
      return of(new HttpResponse({body: [{error: "Unknown Error"}]}));
    }catch(e){
      return of(new HttpResponse({body: [{error: "Unknown Error"}]}));
    }
  }));
  }

  private modifyBody(event: any) {
   if(event.url.includes('verifyemail') && !event.body.status )
      event.body.status="valid"
  }





}
