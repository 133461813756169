import { Injectable } from '@angular/core';
import {ConstantService} from 'src/app/shared/constant/constant.service'
import {Router} from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  storageKey = ConstantService.localStorageKeys.token;
  userDataKey = ConstantService.localStorageKeys.userData;
  logout_success: String;
  logout_msg: String;

  constructor(private router: Router) { }

  logoutUser(){
    localStorage.clear();
    this.removeToken();
    this.router.navigate(['/login']);
  }

  removeToken(){
    localStorage.removeItem(this.storageKey);
    localStorage.removeItem(this.userDataKey);
  }

  setToken(token: string){
    localStorage.setItem(this.storageKey, token);
  }

  getToken(){
    return localStorage.getItem(this.storageKey);
  }

  isAuthenticated():boolean{
    if(this.getToken()){
      return true;
    }
    return false;
  }
}
