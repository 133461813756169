import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantService } from 'src/app/shared/constant/constant.service'
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class ApicallhelperService implements OnInit {


  constructor(private http: HttpClient, private constantService: ConstantService) {
  }

  ngOnInit() {

    // subscribe to router event
  }
  public postcall(urlkey, data, reqheaderkey, params) {
    if (reqheaderkey === null)
      return this.http.post(ConstantService.apiRoutes[urlkey], data, { params: params });
    else
      return this.http.post(ConstantService.apiRoutes[urlkey], data, { params: params, headers: ConstantService[reqheaderkey] });
  }

  
  public getcall(urlkey, reqheaderkey, params, id) {

    var callingurl;
    if (id === null)
      callingurl = ConstantService.apiRoutes[urlkey];
    else
      callingurl = ConstantService.apiRoutes[urlkey] + '/' + id;

      // console.log(urlkey +" "+callingurl);

        if(reqheaderkey === null)          
            return  this.http.get(callingurl ,{ params: params} );
        else
          return  this.http.get(callingurl , { params: params, headers: ConstantService[reqheaderkey] });
    }

    public patchcall(urlkey,data,reqheaderkey,params)
    {   

        if(reqheaderkey === null)
          return  this.http.patch(ConstantService.apiRoutes[urlkey] ,data,{ params:params} );
        else
          return  this.http.patch(ConstantService.apiRoutes[urlkey],data , { params:params, headers: ConstantService[reqheaderkey] });
    }
}