import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import myAppConfig from 'src/app/config/my-app-config';
import {OktaSignIn} from '@okta/okta-signin-widget';
import { OktaAuthModule, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
const DEFAULT_ORIGINAL_URI = window.location.origin;

// import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-oktalogin',
  templateUrl: './oktalogin.component.html',
  styleUrls: ['./oktalogin.component.scss']
})
export class OktaloginComponent implements OnInit, OnDestroy {
  oktaSignin: any;
  title = 'angular-okta-sso';
  

  constructor(
    // private OktaAuthService: OktaAuthModule,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) { 
    // this.signin = new OktaSignIn({
    //   /**
    //    * Note: when using the Sign-In Widget for an OIDC flow, you still
    //    * need to configure the base URL for your Okta Org. Here
    //    * we derive it from the given issuer for convenience.
    //    */
    //   baseUrl: myAppConfig.oidc.issuer.split('/oauth2')[0],
    //   clientId: myAppConfig.oidc.clientId,
    //   redirectUri: myAppConfig.oidc.redirectUri,
    //   logo: 'assets/angular.svg',
    //   i18n: {
    //     en: {
    //       'primaryauth.title': 'Sign in to Angular & Company',
    //     },
    //   },
    //   authClient: oktaAuth,
    // });
    this.oktaSignin = new OktaSignIn({
      logo: 'https://ok12static.oktacdn.com/fs/bcg/4/gfs9y44zqxlTbMAQK5d7',
      features: {
        registration: true
      },
      baseUrl: myAppConfig.oidc.issuer.split('/oauth2')[0],
      clientId: myAppConfig.oidc.clientId,
      redirectUri: myAppConfig.oidc.redirectUri,
      authParams: {
        pkce: true,
        issuer: myAppConfig.oidc.issuer,
        scopes: myAppConfig.oidc.scopes
      } 
    })
    
    /** (async () => {
      const state = this.randomStr(40);
      const codeVerifier = randomStr(128);
    
      window.sessionStorage.setItem('state', state);
      window.sessionStorage.setItem('codeVerifier', codeVerifier);
      const sha2566 = await sha256(codeVerifier);
      const buffer  = bufferToBase64UrlEncoded(sha2566)
      // const codeVerifierHash = sha2566.toString();
      console.log(buffer);
      const codeChallenge = buffer
		 .replace(/=/g, '')
		 .replace(/\+/g, '-')
		 .replace(/\//g, '_');

	  const params = [
		 'response_type=code',
		 'state=' + state,
		 'client_id=' + environment.oauthClientId,
		 'scope=openid',
		 'code_challenge=' + codeChallenge,
		 'code_challenge_method=S256',
		 'redirect_uri=' + encodeURIComponent(environment.oauthCallbackUrl),
	  ];
	 
	  let url = environment.oauthLoginUrl + '?' + params.join('&');
	 
	  //alert(url);
	 
	  window.location.href = url;
    })();
    */
    
	  
	 
	  
  }

  private randomStr(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
             
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
             
    return result;
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.oktaSignin.renderEl({
      el: '#okta-sign-in-widget'},
      (response: any) => {
        if(response.status == 'SUCCESS'){
          // this.OktaAuthService.signInWithRedirect();
        }
      },
      (error: any) => {
        console.log(error)
        throw error;
    })
  }

}
