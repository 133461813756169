import { AppGlobals } from '../../../app.global';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Http, Response, Headers } from "@angular/http";
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';


@Injectable()
export class CommonService implements OnInit{

  constructor(private http: HttpClient, private ht: HttpClient) {  }

  ngOnInit() {

    // subscribe to router event
  }
  formpostOnlyid(url)
    {
        // const access_token = localStorage.getItem('userToken');
        // const reqHeader = new HttpHeaders(
        //     { 'Content-Type': 'application/json',
        //     'access_token': access_token,
        //     'Authorization': access_token 
        //   });  
          var userid =localStorage.getItem('userid');         
          const data = {'id': userid};
          return  this.http.post(AppGlobals.API_ENDPOINT + url,data );
    }
    
    formget(url)
    {
        const access_token = localStorage.getItem('userToken');
        const reqHeader = new HttpHeaders(
            { 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': access_token
          });  

          return  this.http.get(AppGlobals.API_ENDPOINT + url, { headers: reqHeader });
    }


    
    formpostForAI(url,data)
    { 
      // var headers = new Headers();
      // headers.append('Access-Control-Allow-Origin', '*');
      // headers.append('Authorization', 'Basic ZWY5ZTU5MDctNjg1My00NmYzLWExN2ItZjgxNDRhZTg2ZGZlOkVZcng3Ymxvdm9lc0VBUkZHcVFnWHNua0NhRXpTWm9V');


        let reqHeader = new Headers(
            {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Origin': '*',              
              'User-Agent': 'Complygate MyApp/v1.0', 
              'secure': 'true',           
              'Authorization': 'Basic ZWY5ZTU5MDctNjg1My00NmYzLWExN2ItZjgxNDRhZTg2ZGZlOkVZcng3Ymxvdm9lc0VBUkZHcVFnWHNua0NhRXpTWm9V'
            }); 
            
           
           //reqHeader.append('Access-Control-Allow-Origin: *', 'utf-8');

          data =JSON.stringify(data);
          // console.log(data);
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', "Req header")
          // console.log(reqHeader);
          //return  this.ht.post(url,data,{ headers: reqHeader });
    }


    formgetForAML(url)
    { 

      const access_token = localStorage.getItem('userTokenAML');

      // console.log('Tanwar kumar');

      // console.log(access_token);
        let reqHeader = new HttpHeaders(
            {
              'Accept': 'application/json',              
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'GET',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'User-Agent': 'Complygate', 
              'secure': 'false',           
              'apiKey': AppGlobals.API_AMLKEY
          }); 
          
          // data =JSON.stringify(data);
          // console.log(data);
          return  this.http.get(AppGlobals.API_AML + url , { headers: reqHeader });
    }

    formgetForAMLParam(url,dataparam)
    { 

      const access_token = localStorage.getItem('userTokenAML');

      // console.log('Tanwar kumar');

      // console.log(access_token);
        let reqHeader = new HttpHeaders(
            {
              'Accept': 'application/json',              
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'GET',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'User-Agent': 'Complygate', 
              'secure': 'false',         
              'apiKey': AppGlobals.API_AMLKEY
          }); 
          
          // data =JSON.stringify(data);
          // console.log(data);
          return  this.http.get(AppGlobals.API_AML + url , {params:dataparam, headers: reqHeader });
    }



    formpostForAML(url,data)
    { 
      const access_token = localStorage.getItem('userTokenAML');
        let reqHeader = new HttpHeaders(
            {
              'Accept': 'application/json',              
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'User-Agent': 'Complygate', 
              'secure': 'false',             
              'apiKey': AppGlobals.API_AMLKEY
          }); 
          
          data =JSON.stringify(data);
          // console.log(data);
          return  this.http.post(AppGlobals.API_AML + url,data , { headers: reqHeader });
    }

    
    formpostForWithoutToken(url,data)
    { 
    
        let reqHeader = new HttpHeaders(
            {
              'Accept': 'application/json',              
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'User-Agent': 'Complygate', 
              'secure': 'false'                         
          }); 
          
          data =JSON.stringify(data);
          // console.log(data);
          return  this.http.post(AppGlobals.API_AML + url,data , { headers: reqHeader });
    }


    formpostForWithoutTokenForrighttowork(url,data)
    { 
    
        let reqHeader = new HttpHeaders(
            {
              'Accept': 'application/json',              
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'User-Agent': 'Complygate', 
              'secure': 'false'                         
          }); 
          
          data =JSON.stringify(data);
          // console.log(data);
          return  this.http.post(AppGlobals.API_ENDPOINT + url,data , { headers: reqHeader });
    }


    formpost(url,data)
    {        
      // const access_token = localStorage.getItem('userToken');
      //   const reqHeader = new HttpHeaders(
      //       { 'Content-Type': 'application/json',
      //       'access_token': access_token,
      //       'Authorization': access_token
      //     });  

          return  this.http.post(AppGlobals.API_ENDPOINT + url,data );
    }

    getAddress(searchValue)
    {
        return  this.http.get("https://api.getaddress.io/find/" + searchValue + "?api-key=nvy3UeN_uUawvYdusmIq_w11408");
    }

    getAI(searchValue)
    {
      const reqHeader = new HttpHeaders(
        { 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': "GET, POST, DELETE, PUT"
      });  
        return  this.http.post("http://127.0.0.1:5000/data",searchValue, { headers: reqHeader });
    }

  

    validateMobileNumber(phone_number)
    {
      var access_key = '60b58a7264636db801b671f61690f0ad';
      return  this.http.get("https://apilayer.net/api/validate?access_key=" + access_key + "&number=" + phone_number);
    }

    changecurrentaddress(url,data)
    {
      const access_token = localStorage.getItem('userToken');
        const reqHeader = new HttpHeaders(
            { 'Content-Type': 'application/json',
            'access_token': access_token,
            'Authorization': 'Bearer' + access_token
          });  

          return  this.http.post(AppGlobals.API_ENDPOINT + url,data, { headers: reqHeader });
    }  

}
