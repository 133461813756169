// import 'pace';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NavbarComponent } from './components/navbar/navbar.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from 'src/app/shared/module/material/material.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToasterComponent } from './components/toaster/toaster.component';
import {WebcamModule} from 'ngx-webcam';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from 'src/app/services/core/interceptors/token-interceptor.service';
import { AuthGuardService } from 'src/app/services/core/guards/auth-guard.service';
import { AuthService } from 'src/app/services/core/auth/auth.service';
// import { Token } from '@angular/compiler/src/ml_parser/lexer';

import { AgmCoreModule } from '@agm/core';
import { AppConfig } from './app.config';
import { ErrorComponent } from './pages/error/error.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxCsvParserModule } from 'ngx-csv-parser';

import {ApicallhelperService} from 'src/app/shared/helper/apicallhelper.service'
//import { FilterPipe } from './pipes/filter.pipe';
import { CommonService } from 'src/app/services/common/common/common.service';
//import { ModalService } from 'src/app/services/common/common/modal.service'
//import { ModalService } from 'src/app/_services/modal.service'
import { UploadFileService } from 'src/app/services/common/common/upload-file.service';
import { DatePipe, APP_BASE_HREF } from '@angular/common';
import { AlertService } from './services/common/common/alert.service';

import { ModalModule } from './pages/admin/_modal';
import { ModalComponent } from './_directives';
import { DirectivesComponent } from './directives/directives.component';
import { ModaldirComponent } from './directives/modaldir.component';
import { CustomerPersonalDetailsComponent } from './customer-personal-details/customer-personal-details.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ChartsModule } from 'ng2-charts';
import {MsalModule,MsalService, MSAL_INSTANCE} from '@azure/msal-angular';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';




  



// import { ChartModule } from 'angular-highcharts';
// import { HighchartsChartModule } from 'highcharts-angular';
// import { HighchartsChartModule } from 'highcharts-angular';
// import { ChartModule } from 'angular-highcharts';
// import { HighchartsChartModule  } from 'highcharts-angular';
 
import myAppConfig from './config/my-app-config';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: myAppConfig.oidc.clientId,
      redirectUri: window.location.origin + '/login/callback',
      postLogoutRedirectUri: "http://localhost:4200/login"
    }
  });
}
// import { HighchartsChartModule  } from 'highcharts-angular';

import {NgIf, JsonPipe} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
// import { PlaceComponent } from './features/autocomplete/place/place.component';
// config: AppConfig;
// const oktaConfig = Object.assign({
//   onAuthRequired: (oktaAuth: any, injector: Injector) => {
//     const router = injector.get(Router);
//     router.navigate(['/login'])
//   }
// },
// myAppConfig.oidc);
const oktaAuth = new OktaAuth({
  issuer: myAppConfig.oidc.issuer,
  clientId: myAppConfig.oidc.clientId,
  redirectUri: window.location.origin + '/login/callback'
});

@NgModule({
  declarations: [
    AppComponent,
    //NavbarComponent,
    
    PageNotFoundComponent,
    ToasterComponent,
    //FilterPipe
    ModalComponent,
    ModaldirComponent,
    DirectivesComponent,
    CustomerPersonalDetailsComponent,
    // PlaceComponent,
  ],
  imports: [
    
    ModalModule,
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    // HighchartsChartModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    ToastrModule.forRoot(),
    LayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    OktaAuthModule.forRoot({ oktaAuth }),
   
    // ChartModule,
    // HighchartsChartModule,
    NgxCsvParserModule,
    WebcamModule,
    UiSwitchModule,
    ChartsModule
  ],
  providers: [
    DatePipe,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },    
    MsalService,
    UploadFileService,
    CommonService, 
    AppConfig,
    AuthService,
    AlertService,
    AuthGuardService,
    CommonService,
    ApicallhelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
  entryComponents: [ToasterComponent]
})
export class AppModule { }
