import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserauthguardGuard } from './userauthguard.guard';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import {AuthGuardService} from 'src/app/services/core/guards/auth-guard.service'

import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { OktaloginComponent } from './features/loginregister/components/oktalogin/oktalogin.component';
const routes: Routes = [
  {
    path: '',
    //loadChildren: 'src/app/features/loginregister/loginregister.module#LoginregisterModule'
    loadChildren: () => import('./features/loginregister/loginregister.module').then(m => m.LoginregisterModule)
  },
  { 
    path: 'pages', canActivate: [AuthGuardService], loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  { 
    path: 'login/callback', component: OktaCallbackComponent
  },
  { 
    path: 'oktalogin', component: OktaloginComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
